/*global chrome*/
/*eslint no-undef: "error"*/

import { useEffect, useState } from 'react'
import './commonStyles.css'
import { Elements } from '@stripe/react-stripe-js'
import CheckoutForm from './checkoutform'
import { loadStripe } from '@stripe/stripe-js'

function Payment () {
  const [stripePromise, setStripePromise] = useState(null)
  const [clientSecret, setClientSecret] = useState('')
  const [transactionId, setTransactionId] = useState(null)
  const [redirectId, setRedirectId] = useState(null)
  const [cost, setCost] = useState(null)
  const baseUrl = process.env.REACT_APP_DAPTIN_URL
  const stripePKey = process.env.REACT_APP_STRIPE_PK

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search)
    const customer_id = queryParameters.get('customer_id')
    const package_id = queryParameters.get('package_id')
    const redirect_id = queryParameters.get('redirect_id')

    if (clientSecret === '' && stripePromise == null) {
      //request tokem from extension
      chrome.runtime.sendMessage(
        redirect_id,
        {
          type: 'requestPhToken'
        },
        response => {
          console.log('Called back response [PAY] ', response)
          prepareAndInitiatePayment(customer_id, package_id, redirect_id, response.token)
        }
      )
    }
  }, [])

  const prepareAndInitiatePayment = async (
    customer_Id,
    package_Id,
    redirect_id,
    token
  ) => {
    // const tokenValue = await navigator.clipboard.readText();
    setStripePromise(loadStripe(stripePKey))
    var transaction = ''
    if (transactionId == null) {
      transaction = generateNewTransactionId()
      setTransactionId(transaction)
      setRedirectId(redirect_id)
      fetch(`${baseUrl}/action/payment/initiate_transaction`, {
        method: 'POST',
        headers: {
          'ngrok-skip-browser-warning': 'e',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          transaction_id: transaction,
          currency: 'usd',
          customer_id: customer_Id,
          package_id: package_Id
        })
      }).then(
        async result => {
          console.log('Status code')
          var response = await result.status
          console.log(response)
          if (result.ok) {
            var resp = await result.json()
            const secretKey = resp[0].Attributes.render_cs
            const costUSD = resp[0].Attributes.cost
            console.log(secretKey)
            setClientSecret(secretKey)
            setCost(costUSD)
          }
        },
        async error => {
          console.log('Exception returned')
          console.log(error)
        }
      )
    }
  }

  const generateNewTransactionId = () => {
    return Math.random().toString(36).slice(2)
  }

  return (
    <div className='flex-container basebg'>
      <svg
        width='155'
        height='43'
        viewBox='0 0 155 43'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M20.9824 12.2063L25.6751 4H29.4534C33.547 4 36.8655 7.31851 36.8655 11.4121V40.0016H27.841V15.3829C27.841 13.6285 26.4188 12.2063 24.6644 12.2063H20.9824Z'
          fill='#3870FF'
        />
        <path
          d='M74.984 22.0008C74.984 31.9424 66.9248 40.0016 56.9832 40.0016C47.0417 40.0016 38.9824 31.9424 38.9824 22.0008C38.9824 12.0592 47.0417 4 56.9832 4C66.9248 4 74.984 12.0592 74.984 22.0008ZM47.9828 22.0008C47.9828 26.9716 52.0124 31.0012 56.9832 31.0012C61.954 31.0012 65.9836 26.9716 65.9836 22.0008C65.9836 17.03 61.954 13.0004 56.9832 13.0004C52.0124 13.0004 47.9828 17.03 47.9828 22.0008Z'
          fill='#3870FF'
        />
        <path
          d='M97.752 40.0039C104.62 40.0039 109.532 38.2832 112.816 33.9154L134.018 4.00229H123.203L107.064 26.0871C104.181 30.109 102.114 31.0035 97.752 31.0035V40.0039Z'
          fill='#3870FF'
        />
        <path
          d='M97.752 13.0027C102.064 13.0027 103.826 13.7968 108.271 17.9L113.16 11.4144C107.826 6.12003 104.837 4.00229 97.752 4.00229V13.0027Z'
          fill='#3870FF'
        />
        <path
          d='M122.525 40.0039H132.155L123.118 25.8415L117.932 32.8565L122.525 40.0039Z'
          fill='#3870FF'
        />
        <path
          d='M95.1024 40.0016C90.3283 40.0016 85.7497 38.1051 82.3739 34.7293C78.9981 31.3535 77.1016 26.7749 77.1016 22.0008C77.1016 17.2267 78.9981 12.6481 82.3739 9.27231C85.7497 5.89651 90.3283 4 95.1024 4L95.1024 13.0004C92.7153 13.0004 90.426 13.9487 88.7381 15.6366C87.0502 17.3245 86.102 19.6138 86.102 22.0008C86.102 24.3879 87.0502 26.6772 88.7381 28.3651C90.426 30.053 92.7153 31.0012 95.1024 31.0012L95.1024 40.0016Z'
          fill='#3870FF'
        />
      </svg>

      <h2 className='jsf-bold'>Credit refill</h2>

      <div>{cost != null ? <p>Cost : {cost} USD</p> : 'Loading...'}</div>

      <div className='payform'>
        {clientSecret && stripePromise && (
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            <CheckoutForm
              transactionId={transactionId}
              redirectId={redirectId}
            />
          </Elements>
        )}
      </div>
    </div>
  )
}

export default Payment
