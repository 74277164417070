/*global chrome*/
/*eslint no-undef: "error"*/
import './App.css'

import { Routes, Route } from 'react-router-dom'
import Payment from './stripe/v2/payment'
import Completion from './stripe/v2/completion'

function App () {
  return (
    <div>
      <Routes>
        <Route path='/' element={<GreetComponent />} />
        <Route path='/pay' element={<Payment />} />
        <Route path='/completion' element={<Completion />} />
      </Routes>
    </div>
  )
}

function GreetComponent () {
  return (
    <div>
      <h2>Welcome to the 100x payment handler</h2>
      {/* <button
        onClick={() => {
          var statusObj = {
            message: 'refresh_customer'
          }
          chrome.runtime.sendMessage(
            'ibjkagefhjdpbplhfphjonaofmllgaab',
            statusObj,
            () => {
              console.log('Called back')
            }
          )
        }}
      >
        Send to
      </button> */}
    </div>
  )
}

export default App
