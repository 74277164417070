/*global chrome*/
/*eslint no-undef: "error"*/

import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import useWindowDimensions from '../../utils/useWindowSizeUtil'
import Confetti from 'react-confetti'
import './commonStyles.css'

function Completion (props) {
  const [status, setStatus] = useState('Success')
  const [currentCredits, setCurrentCredits] = useState(0)
  const [isConfettiVisible, setIsConfettiVisible] = useState(false)
  const { state } = useLocation()
  const baseUrl = process.env.REACT_APP_DAPTIN_URL
  const CONFETTI_DURATION_MS = 7000 //display confetti for 10 seconds

  useEffect(() => {
    startConfetti()
  }, [])

  const startConfetti = () => {
    //send a message to refresh customer
    var statusObj = {
      message: 'refresh_customer'
    }
    //use redirect id
    chrome.runtime.sendMessage(
      state.redirectId,
      statusObj,
      () => {
        console.log('Called back')
      }
    )
    //start confetti if not already started - use localstorage to prevent showing confetti too many times
    if (!isConfettiVisible) {
      setIsConfettiVisible(!isConfettiVisible)
      setTimeout(() => {
        setIsConfettiVisible(false)
        window.close()
      }, CONFETTI_DURATION_MS)
    }
  }

  const { width, height } = useWindowDimensions()
  return (
    <div>
      {isConfettiVisible && (
        <Confetti
          width={width}
          height={height}
          numberOfPieces={3000}
          recycle={true}
        />
      )}
      <div className='flex-container'>
        <h2>Payment successful</h2>
        <p>You can close this tab</p>
      </div>
    </div>
  )
}

export default Completion
