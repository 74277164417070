import { PaymentElement } from '@stripe/react-stripe-js'
import { useState } from 'react'
import { useStripe, useElements } from '@stripe/react-stripe-js'
import { useNavigate } from 'react-router-dom'

export default function CheckoutForm ({ transactionId, redirectId }) {
  const stripe = useStripe()
  const elements = useElements()
  const navigate = useNavigate()

  const [message, setMessage] = useState(null)
  const [isProcessing, setIsProcessing] = useState(false)

  const handleSubmit = async e => {
    e.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    setIsProcessing(true)

    const response = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
      },
      redirect: 'if_required'
    })

    console.log('Checkout response')
    console.log(response)
    console.log('TransactionId')
    console.log(transactionId)

    if (Object.hasOwn(response, 'paymentIntent')) {
      navigate("/Completion",{state: {redirectId: redirectId}})
    } else if (Object.hasOwn(response, 'error')) {
      setMessage(response.error.message)
    }
    console.log(message)
    setIsProcessing(false)
  }

  const generateConfStateIdentifier = () => {
    return Math.random().toString(36).slice(2)
  }

  return (
    <form id='payment-form' onSubmit={handleSubmit}>
      <PaymentElement id='payment-element' />
      <button disabled={isProcessing || !stripe || !elements} id='submit'>
        <span id='button-text'>
          {isProcessing ? 'Processing ... ' : 'Pay now'}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id='payment-message'>{message}</div>}
    </form>
  )
}
